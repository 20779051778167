// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    // API_URl: "https://abbott-ph-api-dev.spurtreetech.com/api"
    API_URl: "https://abbott-api-uat.sttarter.com/api",
      
    AUTH_BASE_URL: 'https://apac-s3wrapper.capillarytech.com',
    API_BASE_URL:'https://apac-apigateway.capillarytech.com/mobile/v2/api',
    //SALESFORCE_BASE_URL:' https://abbott-ph-api-dev.sttarter.com/api/v1',
    SALESFORCE_BASE_URL: "https://abbott-api-uat.sttarter.com/api/v1",
    CMS_URL:'https://api.sttarter.com/contentsystem/d3eaf7a8067373ea08c47134748d7abf',
    API_URl_TRACEBILITY: "https://abbott-api-uat.sttarter.com/api",
    IMAGE_UPLOAD:"https://apac-apigateway.capillarytech.com/mobile/v2/",
    //EMAIL_CHECK_API: "https://abbott-ph-api-dev.sttarter.com/api/v1",
    EMAIL_CHECK_API: " https://abbott-api-uat.sttarter.com/api/v1",
    BRAND: 'ABBOTTDEMO',
    DEVICE_ID: '1234551231',
    IDENTIFIER_TYPE:"MOBILE",
    TILL_ID:"abbottvntemppage",
    STTARTER_GAMEPLAY_REWARD:"https://api.sttarter.com/contentsystem/f148dba495e5391a7790087cbb5fc3e2",
    STTARTER_GAMEPLAY_FIRSTTIME_SCAN:"https://api.sttarter.com/contentsystem/f148dba495e5391a7790087cbb5fc3e2/firsttimescanuat",
    STTARTER_GAMEPLAY_LUCKY_DRAW:"https://api.sttarter.com/contentsystem/f148dba495e5391a7790087cbb5fc3e2/luckydrawuat",
    // GAME_MAP_API: "https://cap-intouch-in.sttarter.com",
    GAME_MAP_API: 'https://cap-apac-api-com.sttarter.com/v1.1',
    HOLDING_URL: "https://cap-intouch-in.sttarter.com",
    WRAPPER_MIDDLEWARE_API: "https://abbott-api-uat.sttarter.com/api/v1",
    COUNTRY_CODE: '91',
    MOBLIMIT:10,


    gameBrandId :38,
    gameIdGrowNonMilk : 215,
    gameIdSimilacMom : 213,
    gameIdSimilacS4 : 214,

    seriescode1:"8461",
    seriescode2:"8462",
    seriescode3:"8463",
    bugsnag_version: '26082024'
  };
  
  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/dist/zone-error';  // Included with Angular CLI.
  